import React from 'react'
import {Col, Form, Row} from "react-bootstrap";
import Api from "../../../helpers/Api";
import SubmitButton from "../components/SubmitButton";
import Heading from "../../../Components/Heading/Heading";
import FileUpload from "./FileUpload/FileUpload";
import HTMLEditor from "../../../Components/HTMLEditor";

const DATA = {
    type: '',
    title: '',
    description1: '',
    description2: '',
    image:'',
    skills_title:'',
    skills: [
        {
            id: '',
            src: '',
            description:
                '',
        }
    ],
    fitness_title:'',
    fitness_description:'',
    should: [
        ''
    ],
    not: [
        '',
    ],
    lead: {
        name: '',
        image:'',
        title: '',
        description: '',
        companyLogos:[
            {image:'',}
        ],
        linkedin:''
    },
    lead2: {
        name: '',
        image:'',
        title: '',
        description: '',
        companyLogos:[
            {image:'',}
        ],
        linkedin:''
    },
    learnings: {
        title: '',
        description: '',
        topics: [
            {
                id: '',
                src: '',
                title: '',
                topicNumber: '',
                text: '',
            }
        ]
    },
    faq: [
        {
            question:
                '',
            answer:
                '',
        }
    ],
    careerBoost: {
        title: '',
        slides: [
            {
                title: '',
                description: '',
            }
        ]
    },
    dean: {
        title: '',
        name: '',
        image:'',
        description: '',
        message: {
            description: '',
            video: '',
        },
        companyLogos:[
            {image:'',}
        ],
        linkedin:''
    },
    why: {
        title: '',
        description: '',
        image:''
    },
    method: {
        image:'',
        title: '',
        description: ''
    },
    cornerstones: {
        title: '',
        section1: {
            title: '',
            items: ['']
        },
        section2: {
            title: '',
            items: ['']
        }
    },
    showTopics: false,
    home_enabled: false,
    home_title: '',
    apply_url:'',
    miro_embed_code:'',
    schedule_type:'',
    hashtags: [
        ''
    ],
    enableEnrollment: false,
    workflowId: '',
}
const DATA_ORDER = {
    type: -1,
    title: 0,
    description1: 1,
    description2: 2,
    image: 3,
    skills_title: 4,
    skills: 5,
    fitness_title: 6,
    fitness_description: 7,
    should: 8,
    not: 9,
    lead: 10,
    lead2: 10,
    learnings: 11,
    faq: 12,
    careerBoost: 13,
    dean: 14,
    why: 15,
    method: 16,
    cornerstones: 17,
    showTopics: 18,
    home_enabled: 19,
    home_title: 20,
    duration: 21,
    apply_url: 22,
    miro_embed_code: 22,
    schedule_type: 22,
    hashtags: 22,
    enableEnrollment: 23,
    workflowId: 24,
}

class ProgramForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            post: {},
            files: []
        }
    }

    componentDidMount() {
        let {match: {params}} = this.props;
        if (params.id) {
            this.loadData(params.id);
        }
    }

    loadData = (id) => {
        if (id === 'new') {
            return;
        }
        Api.call(`/programs/${id}`).then(post => {
            if(!post.data.showTopics){
                post.data.showTopics = false;
            }
            if(!post.data.dean.title){
                post.data.dean.title = 'Message from Dean';
            }
            if(!post.data.dean.description){
                post.data.dean.description = '';
            }
            if(!post.data.dean.linkedin){
                post.data.dean.linkedin = '';
            }
            if(!post.data.lead.linkedin){
                post.data.lead.linkedin = '';
            }
            if(!post.data.lead.companyLogos){
                post.data.lead.companyLogos = [{image:''}];
            }
            if(!post.data.dean.companyLogos){
                post.data.dean.companyLogos = [{image:''}];
            }
            if(!post.data.skills_title){
                post.data.skills_title = '';
            }
            if(!post.data.home_title){
                post.data.home_title = '';
            }
            if(!post.data.duration){
                post.data.duration = '';
            }
            if(!post.data.home_enabled){
                post.data.home_enabled = false;
            }
            if(!post.data.apply_url){
                post.data.apply_url = '';
            }
            if(!post.data.miro_embed_code){
                post.data.miro_embed_code = '';
            }
            if(!post.data.schedule_type){
                post.data.schedule_type = '';
            }
            if(!post.data.type){
                post.data.type = '';
            }
            if(!post.data.workflowId){
                post.data.workflowId = '';
            }
            if(!post.data.lead2){
                post.data.lead2 = {
                    name: '',
                    image:'',
                    title: '',
                    description: '',
                    companyLogos:[
                        {image:'',}
                    ],
                    linkedin:''
                }
            }
            if(!post.data.hashtags){
                post.data.hashtags = [''];
            }
            if(!post.data.enableEnrollment){
                post.data.enableEnrollment = false;
            }
            this.setState({post});
        }).catch(console.log).finally(() => {
            this.setState({loading: false});
        });
    };

    submit = (e) => {
        e.preventDefault();
        if (this.state.post.id) {
            this.update();
        } else {
            this.create();
        }
    };

    update = () => {
        this.setState({loading: true});
        Api.call(`/programs/${this.state.post.id}`, "POST", this.state.post).then(post => {

            this.setState({post: post});
            this.props.history.push('/app/programs');
        }).catch(console.log).finally(() => {
            this.setState({loading: false});
        })
    };
    create = () => {
        this.setState({loading: true});
        let {post, files} = this.state;
        let file = files.thumbnail;


        let formData = new FormData();
        formData.append("data", JSON.stringify(post));
        console.log(JSON.stringify(post));
        console.log(JSON.stringify(post));
        if (file) {
            formData.append("files[thumbnail]", file);
        }
        console.log(formData);
        Api.call(`/programs`, "POST", post, false).then(post => {
            this.setState({post: post});

            this.props.history.push('/app/programs');
        }).catch(console.log).finally(() => {
            this.setState({loading: false});
        })
    };
    onChange = (key, value) => {
        let {post} = this.state;
        post[key] = value;
        this.setState({post: post});
    };

    setToValue = (obj, value, path) => {
        path = path.split('.');
        var i;
        for (i = 0; i < path.length - 1; i++)
            obj = obj[path[i]];

        obj[path[i]] = value;
    }

    onFileChange = (key, value) => {
        console.log(key, value);
        let {files} = this.state;
        files[key] = value;
        var reader = new FileReader();

        this.setState({files: files});
        let url = reader.readAsDataURL(value);

        reader.onloadend = function (e) {
            this.setState({
                previewUrl: [reader.result]
            });
        }.bind(this);
    };

    sortedData = (data)=>{
        return Object.keys(data).sort(function(x, y) {
            if (DATA_ORDER[x] < DATA_ORDER[y]) {
                return -1;
            }
            if (DATA_ORDER[x] > DATA_ORDER[y]) {
                return 1;
            }
            return 0;
        }).reduce(
            (obj, key) => {
                obj[key] = data[key];
                return obj;
            },{});
    };

    render() {
        let {post} = this.state;
        let data = post.data;
        if (!data) {
            data = JSON.parse(JSON.stringify(DATA));
        }
        data = this.sortedData(data);
        return (
            <div className="dashboard">

                <Heading name="Edit"/>

                <div style={{marginTop: '70px'}}>
                    <Row>
                        <Col md={6}>
                            <Form autoComplete={'off'} onSubmit={this.submit}>
                                <Form.Group controlId="price">
                                    <Form.Label>Key</Form.Label>
                                    <Form.Control type="text" value={post.key}
                                                  onChange={event => this.onChange('key', event.target.value)}
                                                  placeholder=""/>
                                </Form.Group>


                                <Form.Group controlId="enabled">
                                    <Form.Check type="checkbox" checked={post.enabled}
                                                onChange={event => this.onChange('enabled', event.target.checked)}
                                                label="Enabled"/>
                                </Form.Group>


                                <Component border={false} data={data} label={''} onChange={(d) => {
                                    this.onChange('data', d);
                                }}/>

                                <SubmitButton label={'Submit'} loading={this.state.loading}/>
                            </Form>
                        </Col>
                    </Row>
                </div>
            </div>
        )
    }
}

const Component = ({data, onChange, label, allowDelete, onDelete,border=true, flexDirection='column'}) => {
    if (typeof data === 'string') {

        let component = <Form.Group controlId="enabled">
            <Form.Group controlId="price">
                <Form.Label>{label.replaceAll('_',' ')} {allowDelete && <button className={'float-right'} type={'button'} onClick={onDelete}>Delete</button>}</Form.Label>
                <Form.Control
                    type={'text'}
                    value={data}
                    onChange={event => onChange(event.target.value)}
                    placeholder=""/>
            </Form.Group>
        </Form.Group>

        if (label.includes('description')) {
            component = <Form.Group controlId="enabled">
                <Form.Group controlId="price">
                    <Form.Label>{label.replaceAll('_',' ')} {allowDelete && <button className={'float-right'} type={'button'} onClick={onDelete}>Delete</button>}</Form.Label>
                    <Form.Control
                        type='text'
                        as='textarea'
                        value={data}
                        onChange={event => onChange(event.target.value)}
                        placeholder=""/>
                </Form.Group>
            </Form.Group>;
        }

        if (label.includes('schedule_type')) {
            component = <Form.Group controlId="schedule_type">
                <Form.Group controlId="schedule_type">
                    <Form.Label>{label.replaceAll('_',' ')} {allowDelete && <button className={'float-right'} type={'button'} onClick={onDelete}>Delete</button>}</Form.Label>
                    <Form.Control
                        as='select'
                        value={data}
                        onChange={event => onChange(event.target.value)}
                        >
                        <option value=""></option>
                        <option value="product-manager">product-manager</option>
                        <option value="product-designer">product-designer</option>
                        <option value="marketing-sales">marketing-sales</option>
                        <option value="software-engineer">software-engineer</option>
                    </Form.Control>
                </Form.Group>
            </Form.Group>;
        }

        if (label.includes('type')) {
            component = <Form.Group controlId="type">
                <Form.Group controlId="type">
                    <Form.Label>{label.replaceAll('_',' ')} {allowDelete && <button className={'float-right'} type={'button'} onClick={onDelete}>Delete</button>}</Form.Label>
                    <Form.Control
                        as='select'
                        value={data}
                        onChange={event => onChange(event.target.value)}
                        >
                        <option value=""></option>
                        <option value="short">short</option>
                        <option value="long">long</option>
                    </Form.Control>
                </Form.Group>
            </Form.Group>;
        }
        if (label.includes('answer')) {
            component = <Form.Group controlId="enabled">
                <Form.Group controlId="price">
                    <Form.Label>{label.replaceAll('_',' ')} {allowDelete && <button className={'float-right'} type={'button'} onClick={onDelete}>Delete</button>}</Form.Label>
                    <HTMLEditor value={data} onChange={onChange}/>
                </Form.Group>
            </Form.Group>;
        }


        if (label.includes('src') || label.includes('image')) {
            component = <FileUpload label={label} data={data} onChange={onChange}/>
        }

        return <>
            {component}
        </>;
    }
    if(typeof data === 'boolean'){
        return <Form.Group controlId={label}>
            <Form.Check type="checkbox" checked={data}
                        onChange={event => onChange(event.target.checked)}
                        label={label.replaceAll('_',' ')}/>
        </Form.Group>
    }
    if (Array.isArray(data)) {
        return <ArrayComponent data={data} label={label} onChange={onChange}/>;
    }
    if (typeof data === 'object') {
        return <><ObjectComponent border={border} data={data} label={label} allowDelete={allowDelete} onDelete={onDelete}
                                  onChange={onChange}/>
        </>;
    }

}

const ArrayComponent = ({data, onChange, label}) => {


    const skeleton = (source) => {
        console.log(typeof source === 'string');
        console.log({source});
        if (typeof source === 'string') {
            return '';
        }
        var o = Array.isArray(source) ? [] : {};
        for (var key in source) {
            if (source.hasOwnProperty(key)) {
                var t = typeof source[key];
                o[key] = t === 'object' ? skeleton(source[key]) : {string: '', number: 0, boolean: false}[t];
            }
        }
        return o;
    }

    return <div className={'array'}>
        <div><span className={'title'}>{label.replaceAll('_',' ')}</span>
            <button className={'float-right'} type={'button'} onClick={() => {let _data = [...data];_data.push(skeleton(_data[0]));onChange(_data);}}>Add</button>
        </div>
        {data.map((d, index) => {
            return <><Component data={d} onChange={(v) => {
                let _data = [...data];
                _data[index] = v;
                onChange(_data);
            }} label={`${index+1}`} flexDirection={'row'} allowDelete={index > 0} onDelete={() => {
                let _data = [...data];
                _data.splice(index, 1);
                onChange(_data);
            }}/>
            </>

        })}
    </div>
}

const ObjectComponent = ({data,border=true, onChange, label, allowDelete, onDelete}) => {
    return <div className={`object ${border ? '': 'no-border' }`}>
        <div><span className={`title`}>{label.replaceAll('_',' ')}
            {allowDelete && <button className={'float-right'} type={'button'} onClick={onDelete}>Delete</button>}
        </span></div>
        {Object.keys(data).map(key => {
            return <Component data={data[key]} onChange={(v) => {
                let d = {...data};
                d[key] = v;
                onChange(d)
            }} label={key}/>
        })}
    </div>
}

export default ProgramForm
